import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/content',
    name: 'Content',
    component: () =>
      import ( /* webpackChunkName: "vessel" */ '../views/ContentDashboard.vue')
  },
  {
    path: '/ops/:id',
    name: 'Ops',
    component: () =>
      import ( /* webpackChunkName: "vessel" */ '../views/OpsDashboard.vue')
  },
  {
    path: '/experience/:id',
    name: 'Experience',
    component: () =>
      import ( /* webpackChunkName: "vessel" */ '../views/ExperienceDashboard.vue')
  },
  {
    path: '/vessels/:mmsi',
    name: 'Vessels',
    component: () =>
      import ( /* webpackChunkName: "vessel" */ '../views/VesselDashboard.vue')
  },
  {
    path: '/weather',
    name: 'Weather',
    component: () =>
      import ( /* webpackChunkName: "weather" */ '../views/WeatherReport.vue')
  },
  {
    path: '/weather/:mmsi',
    name: 'WeatherMMSI',
    component: () =>
      import ( /* webpackChunkName: "weather" */ '../views/WeatherReport.vue')
  },
  {
    path: '/adcp/:station',
    name: 'ADCP',
    component: () =>
      import ( /* webpackChunkName: "adcp" */ '../views/ADCPReport.vue')
  },
  {
    path: '/hycom/operator/:operator',
    name: 'HYCOM',
    component: () =>
      import ( /* webpackChunkName: "hycom" */ '../views/HYCOMReport.vue')
  },
  {
    path: '/nrl/operator/:operator',
    name: 'NRL',
    component: () =>
      import ( /* webpackChunkName: "nrl" */ '../views/NRLReport.vue')
  },
  {
    path: '/operators/:operator',
    name: 'Operators',
    component: () =>
      import ( /* webpackChunkName: "operator" */ '../views/OperatorDashboard.vue')
  },
  {
    path: '/leases/:lease',
    name: 'Leases',
    component: () =>
      import ( /* webpackChunkName: "lease" */ '../views/LeaseDashboard.vue'),
  },
  {
    path: '/platforms/:id',
    name: 'Platforms',
    component: () =>
      import ( /* webpackChunkName: "platform" */ '../views/PlatformDashboard.vue'),
  },
  {
    path: '/rigs/:mmsi',
    name: 'Rigs',
    component: () =>
      import ( /* webpackChunkName: "rig" */ '../views/RigDashboard.vue'),
  },
  {
    path: '/providers/:provider',
    name: 'Providers',
    component: () =>
      import ( /* webpackChunkName: "provider" */ '../views/ProviderDashboard.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import ( /* webpackChunkName: "about" */ '../views/ComingSoon.vue'),
  },
  {
    path: '/trending',
    name: 'Trending',
    component: () =>
      import ( /* webpackChunkName: "trending" */ '../views/Trending.vue'),
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () =>
      import ( /* webpackChunkName: "analytics" */ '../views/Analytics.vue'),
  },
  {
    path: '/api',
    name: 'API',
    component: () =>
      import ( /* webpackChunkName: "api" */ '../views/ComingSoon.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () =>
      import ( /* webpackChunkName: "notFound" */ '../views/PageNotFound.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
