<template>
    <div v-if="!disableLoad" class="loadingScreen" :class="{loadingScreenDone: loadFinished}">
        <img src="@/assets/gomXchange.png" alt="gomXchange logo" class="logoSize">
        <div class="loadText">Loading</div>
    </div>
</template>

<script>
export default {
    name: "loading",
    data: () => ({
        loadFinished: false,
        disableLoad: true
    }),
    methods: {
        startLoading: function() {
            var globv = this;
            console.log("Start loading called");
            globv.disableLoad = false;
            globv.loadFinished = false;
            document.getElementById('app').style.overflow = "hidden";
        },
        finishLoading: function() {
            var globv = this;
            console.log("Finish loading called");
            globv.loadFinished = true;
            document.getElementById('app').style.overflow = null;
            setTimeout(() => {
                globv.disableLoad = true;
            }, 1500);
        }
    }
}
</script>

<style lang="scss">
.logoSize {
    width: 50vw;
}

.loadingScreen {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    background-color: var(--highlightColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;
    z-index: 10;
}

.loadingScreenDone {
    opacity: 0;
    pointer-events: none;
}

.loadText {
    font-size: 5vw;
    animation: loadTextFadeInOut 1s ease-in-out infinite alternate;
    user-select: none;
}

@keyframes loadTextFadeInOut {
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
    }
}

@media (max-width: 1024px) {
    .logoSize {
        width: 90%;
    }

    .loadText {
        font-size: 2rem;
    }
}

</style>