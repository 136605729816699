import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.config.productionTip = false

let state = new Vuex.Store({
  portalUrl: "https://40geo.maps.arcgis.com",
  appId: "QaAzUg6wk6pAuSVK"
})

new Vue({
  router,
  store: state,
  render: h => h(App)
}).$mount('#app')
