<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
//import Portal from '@arcgis/core/portal/Portal';
//import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
//import esriId from '@arcgis/core/identity/IdentityManager';

export default {
  mounted() {
    document.title = "gomXchange";
    this.$store.state.portalUrl = "https://40geo.maps.arcgis.com";
    this.$store.state.appId = "QaAzUg6wk6pAuSVK";
  },
  methods: {
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  height: 100vh;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@import './styles/main.scss';
@import './styles/component.scss';
</style>
