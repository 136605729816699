<template>
  <div style="height: 100%; width: 100%; display: flex; flex-direction: column;">
    <loading ref="loadScreen"/>
    <iframe class="entireView" :src="iframeSrc" frameborder="0" @load="finishLoading()"></iframe>
    <router-link :to="{name: 'Content'}" class="contentBtn">Login for Dashboards</router-link>
    <div class="relevantLinks"> 
      <a href="https://www.40geo.com">
        <img alt="" src="https://images.squarespace-cdn.com/content/5f9afb2bb37e57471b3a025f/1605409991995-Z4E91O5XKN6CV3MYJAKS/40Geo_logo3.png?format=1500w&content-type=image%2Fpng" width="70/" />
      </a>
      <a href="https://www.spire.com">
        <img alt="" src="https://spire.com/wp-content/themes/spire/img/spire_logo_red_white.svg" width="70" />
      </a>
      <a href="https://www.setld.com">
        <img alt="" :src="require('@/assets/setld_logo_v1.2.png')" width="80" />
      </a>
    </div>
  </div>
</template>

<script>
import Loading from '../components/Loading.vue';

export default {
  name: 'Home',
  components: {
    Loading,
  },
  data: () => ({
    iframeSrc: "https://40geo.maps.arcgis.com/apps/webappviewer/index.html?id=58e100dc2ffb44aeb0124e7136263a26"
  }),
  mounted() {
    var globv = this;
    globv.setSrc();
  },
  methods: {
    setSrc: function() {
      var globv = this;
      let mmsi = globv.$route.query.mmsi;
      let baseUrl = "https://40geo.maps.arcgis.com/apps/webappviewer/index.html?id=58e100dc2ffb44aeb0124e7136263a26";
      globv.$refs.loadScreen.startLoading();
      globv.iframeSrc = mmsi ? `${baseUrl}&mmsi=` + mmsi : baseUrl;
    },
    finishLoading: function() {
      var globv = this;
      setTimeout(globv.$refs.loadScreen.finishLoading, 800);
    }
  },
  watch: {
    '$route.query.mmsi': function() {
      var globv = this;
      globv.setSrc();
    }
  }
}
</script>

<style lang="scss">
.relevantLinks {
  display: flex;
  position: absolute;
  bottom: 32px;
  right: 0;

  a {
    padding: 5px 10px;
    display: flex;
    align-items: flex-end;
  }
}

.contentButton {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;

  a {
    padding: 5px 10px;
    display: flex;
    align-items: flex-end;
  }
}

@media (max-width: 480px) {
  .relevantLinks {
    bottom: 68px;
  }
}

.contentBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  align-self: center;
  background-color: #2f55a4;
  color: white;
  background-color: var(--primaryColor);
  color: var(--primaryTextColor);
  text-decoration: none;
  padding: 10px 12px;
  border-radius: 5px;
  transform: scale(1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  &:hover, &:focus, &:active {
      transform: scale(1.1);
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
  }
}
</style>